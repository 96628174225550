import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AiChatService {

    public static async categories() {
        return (await httpResource.get(`/v2/ai-chat/categories`)).data;
    }

    public static async adviceCategories() {
        return (await httpResource.get(`/v2/ai-chat/advice-categories`)).data;
    }

    public static async deleteAdvice(id: string) {
        return (await httpResource.post(`/v2/ai-chat/advice/delete?id=${id}`)).data;
    }

    public static async registerAdvice(id: string, categoryId: string, assumedQuestion: string, response: string) {
        if (id == null) {
            return (await httpResource.post(
                `/v2/ai-chat/advice/register?categoryId=${categoryId}&assumedQuestion=${assumedQuestion}&response=${response}`)).data;
        }
        return (await httpResource.post(
            `/v2/ai-chat/advice/register?id=${id}&categoryId=${categoryId}&assumedQuestion=${assumedQuestion}&response=${response}`)).data;
    }
}