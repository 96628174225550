

















import {Component, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import EnterpriseRooms from "@/components/domains/enterprise/EnterpriseRooms.vue";

@Component({
  components: {EnterpriseRooms}
})
export default class EnterpriseSelection extends Vue {
  name = "EnterpriseSelection";

  // table data
  headers = [
    {text: '企業コード', value: 'agency.code'},
    {text: '企業名', value: 'agency.name'},
    {text: '担当ユーザーID', value: 'userId'},
    {text: '担当ユーザー名', value: 'username'},
    {text: '同時再生上限', value: 'simultaneousPlaybackLimit'},
  ];
  options = {};
  enterprises = [];
  selected = {};

  // dialog
  dialog = false;

  mounted() {
    this.search();
  }

  @Watch('dialog')
  @Watch('options')
  updateTable() {
    this.search();
  }

  search() {

    const page = (this.options as any).page;
    const itemsPerPage = (this.options as any).itemsPerPage;

    if (itemsPerPage == -1) {
      return;
    }

    httpResource.get(`/v2/enterprise/list?page=${page - 1}&size=${itemsPerPage}`)
        .then(result => {
          this.enterprises = result.data
        })
  }

  selectOne(enterprise: any) {
    this.selected = enterprise
    this.dialog = true;
  }
}
