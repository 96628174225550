












































import {Component, Vue} from "vue-property-decorator";
import AgencyService from "@/components/domains/agency/agencyService";
import {Agency} from "@/components/domains/auth/agency";

@Component
export default class AgencyRegisterForm extends Vue {

  dialogTextField = '';
  dialogClassCode = '';
  dialogClass = '';
  dialog = false;

  newAgencyName = '';
  agencies: Agency[] = []; // Adjusted type definition
  headers = [ // Define columns to display
    // {text: 'ID', value: 'id'},
    {text: '教室', value: 'classroom'},
    {text: '店舗名', value: 'name'},
    {text: '店コード', value: 'code'},
    {text: '管轄代理店一覧', value: 'agencies'},
    {text: '代理店追加', value: 'addagency'}
    // {text: 'Status', value: 'status'},
  ];

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.agencies = await AgencyService.fetchAgencies();
  }

  register() {
    AgencyService.registerAgency(this.newAgencyName)
        .then((res) => {
          alert('登録完了: ' + res)
          this.$router.go(0);
        })
  }

  addAgency(classCode: string, agencyCode: string) {

    AgencyService.addAgencyOfClassroom(classCode, agencyCode)
        .then((result) => {
          alert('成功しました。')
        })
        .catch((err) => {
          alert('失敗しました。')
        })
  }
}
