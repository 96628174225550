import {Agency} from "@/components/domains/auth/agency";
import Profile from "@/components/domains/auth/profile";

export class User {

    agency: Agency = new Agency();
    profile: Profile = new Profile();
    id = '';
    userId = '';
    email = '';

    constructor(public username: string = '', public roles: string[] = [], public authorities: Array<string> = []) {
    }

    public get getRoles() {
        return this.roles;
    }

    public isEmpty() {
        return this.id == "";
    }

    public isAdmin(): boolean {
        return this.getRoles.indexOf("ROLE_ADMIN") !== -1;
    }

    public isPIC(): boolean {
        return this.getRoles.indexOf("ROLE_PERSON_IN_CHARGE") !== -1;
    }

    public isCustomer(): boolean {
        return this.getRoles.indexOf("ROLE_CUSTOMER") !== -1;
    }

    public isGeneral(): boolean {
        return this.getRoles.indexOf("ROLE_GENERAL") !== -1;
    }

    public hasAIEstheAuthority(): boolean {
        return this.hasAuthority("AI_ESTHE");
    }

    public hasAuthority(authority: string): boolean {
        return this.authorities.includes(authority);
    }
}
