var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-3 py-0 my-0",attrs:{"label":"代理店名","outlined":"","dense":""},model:{value:(_vm.newAgencyName),callback:function ($$v) {_vm.newAgencyName=$$v},expression:"newAgencyName"}}),_c('v-btn',{staticClass:"my-0 py-0",attrs:{"align":"center","color":"primary"},on:{"click":_vm.register}},[_vm._v("新規登録")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agencies,"item-key":"id"},scopedSlots:_vm._u([{key:"item.classroom",fn:function(ref){
var item = ref.item;
return [(item.agencies.length > 0)?_c('div',[_vm._v(" ○ ")]):_c('div',[_vm._v(" × ")])]}},{key:"item.agencies",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',_vm._l((item.agencies),function(agency){return _c('v-chip',{key:agency.id,staticClass:"mx-1",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(agency.name)+" ")])}),1)]}},{key:"item.addagency",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function () {
          _vm.dialog = !_vm.dialog;
          _vm.dialogClass = item.name;
          _vm.dialogClassCode = item.code;
        }}},[_vm._v("管轄代理店を追加")])]}}])}),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-card-title',[_vm._v("教室と代理店紐づけ登録")]),_c('v-card-text',[_vm._v(" 教室「"+_vm._s(_vm.dialogClass)+"」に紐づける代理店のコードを入力してください。 ")]),_c('v-text-field',{model:{value:(_vm.dialogTextField),callback:function ($$v) {_vm.dialogTextField=$$v},expression:"dialogTextField"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addAgency(_vm.dialogClassCode, _vm.dialogTextField)}}},[_vm._v("紐づける")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }