









import {Vue} from "vue-property-decorator";
import {Component} from "vue-property-decorator";
import AgencyRegisterForm from "@/components/domains/agency/AgencyRegisterForm.vue";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";

@Component({
  components: {MainPaneTemplate, AgencyRegisterForm}
})
export default class Agency extends Vue {
}
