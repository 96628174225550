














































import {Component, VModel, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import EnterpriseSelection from "@/components/domains/enterprise/EnterpriseSelection.vue";
import EnterpriseSections from "@/components/domains/enterprise/EnterpriseSections.vue";
import EnterpriseRoom from "@/components/domains/enterprise/dto/enterpriseRoom";

@Component({
  components: {EnterpriseSections, EnterpriseSelection}
})
export default class EnterpriseRooms extends Vue {
  public name = "EnterpriseRooms";

  @VModel() enterprise: any

  rooms: {
    roomId: string,
    roomName: string,
    displayPriority: number,
    sections: { sectionId: string, sectionName: string }[]
  }[] = [];

  mounted() {
    this.fetchRooms();
  }

  fetchRooms() {
    httpResource.get(`/v2/enterprise/rooms?agencyCode=${this.enterprise.agency.code}`)
        .then(result => {
          this.rooms = result.data
        });
  }

  addRoom() {
    httpResource.post(`/v2/enterprise/room/add?agencyCode=${this.enterprise.agency.code}&roomName=名称未設定の部屋`)
        .then(result => {
          this.fetchRooms();
        });
  }

  delRoom(roomId: string) {
    httpResource.post(`/v2/enterprise/room/delete?roomId=${roomId}`)
        .then(result => {
          this.fetchRooms();
        });
  }

  renameRoom(room: EnterpriseRoom) {
    httpResource.post(`v2/enterprise/room/rename?roomId=${room.roomId}&roomName=${room.roomName}`)
        .then(() => {
          // TODO
        });
  }

  addSection(roomId: string) {
    httpResource.post(`/v2/enterprise/room/section/add?roomId=${roomId}&sectionName=名称未設定の区画`)
        .then(result => {
          this.fetchRooms();
        });
  }
}
