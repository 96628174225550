import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class EquipmentService {

    public static async lineup() {
        return (await httpResource.get('/v2/equipment/lineup')).data;
    }

    public static async registerPhysical(physicalId: string, equipmentId: string, manufacturingId: string) {
        const data = {
            physicalId: physicalId,
            equipmentId: equipmentId,
            manufacturingId: manufacturingId
        };
        return (await httpResource.post('/v2/equipment/register/physical', data)).data;
    }

    public static async returnPhysical(physicalId: string) {
        return (await httpResource.post(`/v2/equipment/return/physical?physicalId=${physicalId}`)).data;
    }
}