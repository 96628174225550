































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import MailService from "@/components/domains/mail/mailService";
import {AuthGetterTypes} from "@/store/auth/types";
import {User} from "@/components/domains/auth/user";
import StockRegisterForm from "@/components/domains/stock/StockRegisterForm.vue";

@Component({
  computed: {
    AuthGetterTypes() {
      return AuthGetterTypes
    }
  },
  components: {StockRegisterForm, MainPaneTemplate}
})
export default class Mail extends Vue {

  name = "Mail"

  // メール
  subject = ""
  inputText = ""
  text = ""
  testing = true
  premium = false;

  disabled = false

  // タブ
  tab = 0;

  // ダイアログ
  dialog = false;
  images: { id: string; imageUrl: string; }[] = [
    // {
    //   id: 'testing',
    //   url: 'https://hero-area.coconala.com/resize/840/472/hero_area_item_app_category/image/1/d1dee4dd-d6f7-46cd-b787-2df5b90ccc00.png'
    // }
  ];
  imageBinary: ArrayBuffer | undefined;

  // 履歴
  mails = [];

  // 編集に関するボタンの押下有無
  isButtonEnabled = true;

  get getCurrentUser(): User {
    return this.$store.getters[AuthGetterTypes.getCurrentUser]
  }

  @Watch('subject')
  watchSubject(): void {
    this.check();
  }

  @Watch('text')
  watchText(): void {
    this.check();
  }

  @Watch('testing')
  watchTesting(): void {
    if (this.testing) {
      this.premium = false;
    }
  }

  @Watch('dialog')
  watchDialog(): void {
    if (this.dialog) {
      this.fetchImages();
    }
  }

  @Watch('tab')
  watchTab(): void {
    if (this.tab == 1) {
      this.fetchMails();
    }
  }

  surroundTextWithTag(tag: string): void {
    const textarea = this.$refs.textarea as any;

    // v-textareaの内部テキスト入力要素を取得
    const inputElement: HTMLTextAreaElement = textarea.$refs.input;

    // 選択されたテキストの開始位置と終了位置を取得
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    // 選択されたテキストを取得
    const selectedText = inputElement.value.substring(start, end);

    // 選択されたテキストが空でない場合に処理
    if (selectedText) {
      // タグで囲む
      const beforeText = this.inputText.slice(0, start);
      const afterText = this.inputText.slice(end);
      const taggedText = `<${tag}>${selectedText}</${tag}>`;

      // 加工されたテキストをv-textareaに設定
      this.inputText = beforeText + taggedText + afterText;

      // タグで囲まれたテキストの後ろにカーソルを設定
      this.$nextTick(() => {
        inputElement.selectionStart = inputElement.selectionEnd = start + taggedText.length;
      });
    }
  }

  check() {
    if (this.subject.trim() == '' || this.text.trim() == '') {
      this.disabled = true
    } else {
      this.disabled = false
    }
  }

  mounted() {
    this.check();
  }

  fetchImages() {
    MailService.images().then((result) => {
      // console.log(result)
      this.images = result;
    });
  }

  fetchMails() {
    MailService.mails().then((result) => {
      // console.log(result)
      this.mails = result;
    })
  }

  sendMail() {

    let result = confirm('送信しますか？');

    if (result) {
      this.disabled = true
      MailService.sendMail(this.subject.trim(), this.text.trim(), this.testing, this.premium).then(() => {
        alert("送信完了");
      }).finally(() => {
        this.disabled = false
      });
      alert("送信が開始されました。")
    }
  }

  deleteImage(img: { id: string, imageUrl: string }) {
    MailService.deleteImage(img.id).finally(() => {
      this.fetchImages();
    })
  }

  handleFileChange(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      const file = files[0];
      MailService.uploadImage(file)
          .catch((error) => {
            alert('ファイルサイズは1MB以内にしてください。')
          })
          .finally(() => {
            this.fetchImages();
          })
    }
  }

  // Mapオブジェクトの作成
  imgMap = new Map();

  selectImg(img: { id: string, imageUrl: string }) {
    const key = `[IMG:${img.id}]`
    this.imgMap.set(key, `<img src="${img.imageUrl}" alt="${img.id}"/>`)
    this.inputText += key
    this.dialog = false;
  }

  triggerFileInput(): void {
    // refを使って隠しファイル入力をトリガーする
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  @Watch('inputText')
  convertedInputText(): void {

    let convertedText = this.inputText;

    // その他の特殊文字をHTMLエンティティに変換
    // convertedText = convertedText.replace(/&/g, '&amp;');
    // convertedText = convertedText.replace(/</g, '&lt;');
    // convertedText = convertedText.replace(/>/g, '&gt;');
    // convertedText = convertedText.replace(/"/g, '&quot;');
    // convertedText = convertedText.replace(/'/g, '&#039;');

    // 絵文字をUnicodeコードポイントに基づいてHTMLの文字エンティティに変換
    convertedText = [...convertedText].map(char => {
      if (char.match(/[\u{1F600}-\u{1F64F}]/u)) { // 絵文字の範囲を指定
        return `&#x${char.codePointAt(0)!.toString(16)};`;
      }
      return char;
    }).join('');

    // 画像を反映
    for (let [key, value] of this.imgMap.entries()) {
      convertedText = convertedText.replace(key, value);
    }

    // 改行をHTMLの改行コードに変換
    convertedText = convertedText.replace(/(?<!<\/h[1-6]>)\n/g, '<br/>');

    this.text = convertedText;
  }

  getHtml(text: string = this.text): string {
    return '<!DOCTYPE html>\n' +
        '<html>\n' +
        '<head>\n' +
        '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
        '    <meta http-equiv="content-style-type" content="text/css">\n' +
        '    <meta http-equiv="content-language" content="ja">\n' +
        '    <title></title>\n' +
        '    <style type="text/css">\n' +
        '        html {\n' +
        '            width: 100%;\n' +
        '            height: 100%;\n' +
        '        }\n' +
        '\n' +
        '        body {\n' +
        '            font-family: Arial, Helvetica, sans-serif;\n' +
        '        }\n' +
        '        table {\n' +
        '            color:black;\n' +
        '        }\n' +
        '        #text {\n' +
        '            color:black;\n' +
        '        }\n' +
        '\n' +
        '        img {\n' +
        '            width: 100%;\n' +
        '        }\n' +
        '    </style>\n' +
        '</head>\n' +
        '<body style="width: 100%;height:100%;margin:0;padding:0;">\n' +
        '<!-- 全体の背景色を指定するためのtable -->\n' +
        '<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ededed"\n' +
        '       style="width:100%;height:100%;background-color:#ededed;">\n' +
        '    <tr>\n' +
        '        <td valign="top">\n' +
        '            <!-- 横幅を指定するためのtable -->\n' +
        '            <table width="600" align="center" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff"\n' +
        '                   style="width:100%;background-color:#ffffff;">\n' +
        '                <tr>\n' +
        '                    <td>\n' +
        '                        <!-- コンテンツを記述するためのtable -->\n' +
        '                        <table width="100%" border="0" cellpadding="20" cellspacing="0">\n' +
        '                            <tr>\n' +
        '                                <td valign="top" align="left">\n' +
        '                                    <p id="text" style="color: black;">\n<br/>' +
        '                                        ' + text +
        '                                    </p>\n' +
        '                                </td>\n' +
        '                            </tr>\n' +
        '                            <tr>\n' +
        '                                <td valign="top" align="left"><br/>\n' +
        '                                    <p>\n' +
        '                                        美容ai.com | ｈａｐｔｉｃｏ株式会社<br/>\n' +
        '                                    </p>\n' +
        '                                </td>\n' +
        '                            </tr>\n' +
        '                        </table>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '            </table>\n' +
        '        </td>\n' +
        '    </tr>\n' +
        '</table>\n' +
        '</body>\n' +
        '</html>\n' +
        '\n'
  }
}
