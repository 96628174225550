







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MainPaneTemplate extends Vue {
  name = "MainPaneTemplate";

  @Prop({default: ''}) title!: string;
}
