<template>
  <v-app>
    <v-app-bar
        app
        color="#FFFF"
        elevate-on-scroll
    >
      <div class="d-flex align-center">
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="/img/icons/android-chrome-512x512.png"
            transition="scale-transition"
            width="40"
        />

        美容ai.com 4 admin
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-fade-transition>
        <slot/>
      </v-fade-transition>
    </v-main>

    <v-footer>
      &copy; 2022 美容ai.com
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "DefaultTemplate"
}
</script>

<style scoped>

</style>