import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class OrderService {

    public static async fetchRequestOrders() {
        return (await httpResource.get('/v2/delivery/order/get-request')).data;
    }

    public static async fetchAllOrders() {
        return (await httpResource.get('/v2/delivery/order/get')).data;
    }

    public static async send(data: {
        deliveryId: string,
        slipNumber: string,
        physicals: { id: string, physicalId: string }[]
    }) {
        return (await httpResource.post('/v2/delivery/order/send', data)).data;
    }

    public static async handingOver(data: {
        deliveryId: string,
        physicals: { id: string, physicalId: string }[]
    }) {
        return (await httpResource.post('/v2/delivery/order/handing-over', data)).data;
    }
}