















































































import {Component, VModel, Vue} from "vue-property-decorator";
import EnterpriseSection from "@/components/domains/enterprise/dto/enterpriseSection";
import EnterpriseDmv from "@/components/domains/enterprise/dto/enterpriseDmv";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import NumberField from "@/components/infrastructures/atoms/NumberField.vue";

@Component({
  components: {NumberField}
})
export default class EnterpriseSectionManagements extends Vue {
  name = "EnterpriseSectionManagements";

  @VModel() sect: EnterpriseSection | undefined;

  dmvList: EnterpriseDmv[] = []
  selectedDmvId = ""

  // fetch section
  fetchIntervalId = 0;
  fetching = false;

  // volume
  volume = 50;
  modifyingVolume = false;

  beforeDestroy() {
    clearInterval(this.fetchIntervalId);
  }

  mounted() {
    httpResource.get('v2/enterprise/dmv/list')
        .then(result => {
          this.dmvList = result.data
        });
    const timeout = 2000;
    this.fetchIntervalId = setInterval(() => {
      this.fetchSection();
    }, timeout);
  }

  addMng() {
    httpResource.post(`v2/enterprise/room/section/management/add?sectionId=${this.sect!.sectionId}&waveFileId=${this.selectedDmvId}`)
        .then(() => {
          this.fetchSection()
        })
  }

  delMng(managementId: string) {
    httpResource.post(`v2/enterprise/room/section/management/delete?managementId=${managementId}`)
        .then(() => {
          this.fetchSection();
        })
  }

  fetchSection() {
    if (this.fetching) {
      return;
    }

    this.fetching = true;
    httpResource.get(`v2/enterprise/room/section?sectionId=${this.sect!.sectionId}`)
        .then((result) => {
          this.sect = result.data;
        })
        .finally(() => {
          this.fetching = false;
        })
  }

  playControl(managementId: string, playing: boolean) {
    httpResource.post(`v2/enterprise/room/section/management/play?managementId=${managementId}&playing=${playing}`)
        .then(() => {
          this.fetchSection();
        })
  }

  modifyVolume(managementId: string, volume: number) {
    this.modifyingVolume = true;
    httpResource.post(`v2/enterprise/room/section/management/volume?managementId=${managementId}&volume=${volume}`)
        .then(() => {
          alert("音量変更に成功しました。");
        })
        .catch(() => {
          alert("音量変更に失敗しました。");
        })
        .finally(() => {
          this.modifyingVolume = false;
        })
  }
}
