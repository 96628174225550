













import {Component, Vue} from "vue-property-decorator";
import EquipmentService from "@/components/domains/equipment/equipmentService";
import ManufacturingService from "@/components/domains/manufacturing/manufacturingService";

@Component
export default class EquipmentStockRegisterForm extends Vue {
  name = "StockRegisterForm.vue";

  // 製品
  equipmentId = "";
  equipments = [];

  // 工場
  manufacturingId = "";
  manufacturings = [];

  // 製造番号
  physicalId = "";

  beforeMount() {

    EquipmentService.lineup()
        .then(productions => {
          this.equipments = productions;
        });

    ManufacturingService.list()
        .then(manufacturings => {
          this.manufacturings = manufacturings;
        })
  }

  // 登録
  register() {
    EquipmentService.registerPhysical(this.physicalId, this.equipmentId, this.manufacturingId)
        .then((id) => {
          // TODO 完了音
          alert(id + ': 登録完了しました。');
        })
        .catch(error => {
          // TODO ビープ音
          alert(error.response.data.message);
        });
  }
}
