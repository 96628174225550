<template>

  <main-pane-template title="製品">
    Coming Soon
  </main-pane-template>
</template>

<script>
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate";
import {Component, Vue} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate";

@Component({
  components: {MainPaneTemplate, LoggedInTemplate}
})
export default class Equipment extends Vue {
  name = "Equipment";
}
</script>

<style scoped>

</style>