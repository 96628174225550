

























import {Component, Vue} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import OrderNewlist from "@/components/domains/order/OrderNewlist.vue";
import OrderHistory from "@/components/domains/order/OrderHistory.vue";

@Component({
  components: {OrderHistory, OrderNewlist, MainPaneTemplate}
})
export default class Order extends Vue {
  name = "Order";
  tab = 0;
}
