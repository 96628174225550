










































import {Component, Vue} from "vue-property-decorator";
import OrderService from "@/components/domains/order/OrderService";
import DeliveryService from "@/components/domains/delivery/DeliveryService";

@Component
export default class OrderHistory extends Vue {
  name = "OrderHistory";

  items = [];

  beforeMount() {
    this.fetchAll();
  }

  complete(item: { id: string }) {
    DeliveryService.complete(item.id)
        .catch((error) => {
          alert(error.response.data.message);
        })
        .finally(() => {
          this.fetchAll();
          this.$router.go(0);
        });
  }

  fetchAll() {
    OrderService.fetchAllOrders().then(res => {
      this.items = res;
    })
  }
}
