import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AgencyService {

    public static async fetchAgencies() {
        return (await httpResource.get('/v3/agency/agencies')).data;
    }

    public static async registerAgency(agencyName: string) {
        return (await httpResource.post(`/v3/agency/register?agencyName=${agencyName}`)).data;
    }

    public static async updateClassroom(agencyCode: string, classroom: boolean) {
        return (await httpResource.post(`/v3/agency/update-classroom?agencyCode=${agencyCode}&classroom=${classroom}`)).data;
    }

    public static async addAgencyOfClassroom(classroomAgencyCode: string, agencyCode: string) {
        return (await httpResource.post(`/v3/agency/add-agency-of-classroom?classroomAgencyCode=${classroomAgencyCode}&agencyCode=${agencyCode}`)).data;
    }
}