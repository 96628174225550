import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class UserService {

    public static async fetchUsers(page = 0, size = 10) {

        const request = {
            page: page,
            size: size
        };

        return (await httpResource.post(`v2/admin/users`, request)).data;
    }

    public static async fetchSubscriptionUsers(page = 0, size = 10) {

        const request = {
            page: page,
            size: size
        };

        return (await httpResource.post(`v2/admin/subscription-users`, request)).data;
    }

    public static async countUsers() {
        return (await httpResource.get(`v2/admin/users/count`)).data;
    }
}