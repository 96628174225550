















import {Component, Vue} from "vue-property-decorator";
import AccountService from "@/components/domains/account/accountService";

@Component
export default class EnterpriseAccountRegisterForm extends Vue {
  name = "EnterpriseAccountRegisterForm"

  // Form
  studioId = "";
  username = "";
  password = "";
  verifyPassword = "";

  // 登録
  register() {
    AccountService.registerStudioAccount(this.studioId, this.username, this.password, this.verifyPassword)
        .then((id) => {
          // TODO 完了音
          alert(id + ': 登録完了しました。');
        })
        .catch(error => {
          // TODO ビープ音
          alert(error.response.data.message);
        });
  }
}
