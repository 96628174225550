

























































































































































import {Component, VModel, Vue} from "vue-property-decorator";
import EnterpriseRoom from "@/components/domains/enterprise/dto/enterpriseRoom";
import EnterpriseSectionManagements from "@/components/domains/enterprise/EnterpriseSectionManagements.vue";
import EnterpriseSection from "@/components/domains/enterprise/dto/enterpriseSection";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import EnterpriseNotification from "@/components/domains/enterprise/dto/enterpriseNotification";

@Component({
  computed: {
    EnterpriseNotification() {
      return EnterpriseNotification
    }
  },
  components: {EnterpriseSectionManagements}
})
export default class EnterpriseSections extends Vue {
  name = "EnterpriseSections";
  @VModel() room: EnterpriseRoom | undefined

  mailAddress = '';
  notification = true;

  renameSection(section: EnterpriseSection) {
    httpResource.post(`v2/enterprise/room/section/rename?sectionId=${section.sectionId}&sectionName=${section.sectionName}`)
        .then(() => {
          // TODO
        });
  }

  releaseSection(section: EnterpriseSection) {
    httpResource.post(`v2/enterprise/room/section/release-duplicate-login?sectionId=${section.sectionId}`)
        .then(() => {
          // TODO
        });
  }

  addNotification(section: EnterpriseSection, mailAddress: string, notification: boolean) {
    httpResource.post(`v2/enterprise/room/section/notification-mail-address?sectionId=${section.sectionId}&mailAddress=${mailAddress}&notification=${notification}`)
        .then(() => {
          this.mailAddress = '';
          this.notification = true;
        });
  }

  modNotification(notificationId: string, mailAddress: string, notification: boolean) {
    httpResource.put(`v2/enterprise/room/section/notification-mail-address?sectionNotificationId=${notificationId}&mailAddress=${mailAddress}&notification=${notification}`)
        .then(() => {
          this.mailAddress = '';
          this.notification = true;
        });
  }

  delNotification(section: EnterpriseSection, notification: EnterpriseNotification) {
    httpResource.delete(`v2/enterprise/room/section/notification-mail-address?sectionNotificationId=${notification.id}`)
        .then(() => {
          // TODO
          section.notifications = section.notifications.filter(n => n.mailAddress !== notification.mailAddress)
        });
  }
}
