import AiChatAdvice from "@/components/domains/analyze/ai_chat/aiChatAdvice";
import AiChatCategory from "@/components/domains/analyze/ai_chat/aiChatCategory";

export default class AiChatAdviceCategory {

    public id = null;

    public advice = new AiChatAdvice();

    public category = new AiChatCategory();
}