import {Vue} from "vue-property-decorator";
import Mixin from "vue-class-component"
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import {User} from "@/components/domains/auth/user";
import authService from "@/components/domains/auth/authService";

@Mixin
export default class AuthMixin extends Vue {

    public get getUser(): User {
        return this.$store.getters[AuthGetterTypes.getCurrentUser];
    }

    beforeCreate() {
        this.$store.dispatch(AuthActionTypes.verifyLogIn).then((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
                this.$router.push("/login");
            } else if (!(this.getUser.roles.includes('ROLE_ADMIN') || this.getUser.roles.includes('ROLE_SUB_ADMIN'))) {
                alert('権限が不正です。');
                this.logout();
            }

        })
    }

    public logout() {
        authService.logout()
            .finally(() => {
                this.$router.push("/login");
            });
    }
}
