import Address from "@/components/domains/address/address";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class ManufacturingService {

    public static async register(name: string, address: Address) {
        const data = {
            name: name,
            address: address
        };
        return (await httpResource.post('/v2/m-loc/register', data)).data;
    }

    public static async list() {
        return (await httpResource.get('/v2/m-loc/list')).data;
    }
}