import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Login from "@/components/pages/Login.vue";
import Home from "@/components/pages/Home.vue";
import Stock from "@/components/pages/loggedin/Stock.vue";
import ManufacturingLocation from "@/components/pages/loggedin/ManufacturingLocation.vue";
import Equipment from '@/components/pages/loggedin/Equipment.vue';
import Order from "@/components/pages/loggedin/Order.vue";
import Analyze from "@/components/pages/loggedin/Analyze.vue";
import Account from "@/components/pages/loggedin/Account.vue";
import EnterpriseManagement from "@/components/pages/loggedin/EnterpriseManagement.vue";
import Mail from "@/components/pages/loggedin/Mail.vue";
import Agency from "@/components/pages/loggedin/Agency.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'enterprise',
                component: EnterpriseManagement
            },
            {
                path: 'agency',
                component: Agency
            },
            {
                path: 'account',
                component: Account
            },
            {
                path: 'analyze',
                component: Analyze
            },
            {
                path: 'mail',
                component: Mail
            },
            {
                path: 'equipment',
                component: Equipment
            },
            {
                path: 'stock',
                component: Stock
            },
            {
                path: 'm-loc',
                component: ManufacturingLocation
            },
            {
                path: 'order',
                component: Order
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
