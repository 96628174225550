






























import {Component, Vue} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import EquipmentStockRegisterForm from "@/components/domains/stock/StockRegisterForm.vue";
import StockService from "@/components/domains/stock/stockService";
import EquipmentService from "@/components/domains/equipment/equipmentService";

@Component({
  components: {StockRegisterForm: EquipmentStockRegisterForm, MainPaneTemplate}
})
export default class Stock extends Vue {
  name = "Stock";

  tab = 0;

  headers = [
    {text: '製品', value: 'production'},
    {text: '製造番号', value: 'physicalId'},
    {text: 'ステータス', value: 'status'},
    {text: '貸出ユーザー', value: 'user.id'},
    {text: '返却処理', value: 'return'},
  ];

  items = [];

  beforeMount() {
    StockService.fetchStocks()
        .then(res => {
          this.items = res;
        });
  }

  returning(item: { physicalId: string }) {
    EquipmentService.returnPhysical(item.physicalId)
        .then(result => {
          // 1 の場合誰にも貸し出していない
          // 0 の場合返却完了
        })
        .finally(() => {
          this.$router.go(0);
        })
  }

  // 製品を取得

  // 製造済み製品の取得

}
