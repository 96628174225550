














import {Component, Vue} from "vue-property-decorator";
import authService from "@/components/domains/auth/authService";
import {User} from "@/components/domains/auth/user";

@Component
export default class LoginForm extends Vue {
  name = "LoginForm";

  loginId = '';
  password = '';

  login() {
    authService.login(this.loginId, this.password)
        .then((user: User) => {
          this.$router.push("/");
        })
        .catch(() => {
          alert('ログインに失敗しました。');
        });
  }
}
