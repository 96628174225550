







import {Component, Vue} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import EnterpriseSelection from "@/components/domains/enterprise/EnterpriseSelection.vue";

@Component({
  components: {EnterpriseSelection, MainPaneTemplate}
})
export default class EnterpriseManagement extends Vue {
  name = "EnterpriseManagement";
}
