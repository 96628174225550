



































































import {Component, Vue} from "vue-property-decorator";
import AiChatService from "@/components/domains/analyze/ai_chat/aiChatService";
import AiChatAdviceCategory from "@/components/domains/analyze/ai_chat/aiChatAdviceCategory";

@Component({
  computed: {
    AiChatAdviceCategory() {
      return AiChatAdviceCategory
    }
  }
})
export default class AiChatLearningForm extends Vue {

  name = "AiChatLearningForm";

  // https://vuetifyjs.com/ja/api/v-data-footer/#props
  tableOptions: {
    page: number,
    itemsPerPage: number
  } = {
    page: 1,
    itemsPerPage: 5
  };

  // dialog
  dialog = false;
  editedItem: AiChatAdviceCategory = new AiChatAdviceCategory();

  // adviceCategory
  adviceCategories = [{}];
  adviceCategoryHeader = [
    // {text: "ID", value: "user.id"},
    // {text: "ID", value: "id"},
    {text: "カテゴリ", value: "category.name"},
    {text: "想定質問", value: "advice.assumedQuestion"},
    {text: "回答", value: "advice.response"},
    {text: "編集", value: "edit"},
    {text: "削除", value: "delete"},
  ];

  // category
  categoryCount = 0;

  categories = [];

  beforeMount() {
    this.fetchAdviceCategories();
    this.fetchCategories();
  }

  editItem(item: any) {
    this.dialog = true;
    this.editedItem = item;
  }

  deleteItem(item: any) {
    const index = this.adviceCategories.indexOf(item);
    const id = (this.adviceCategories[index] as { advice: any }).advice.id
    confirm('削除してもいいですか？')
    && AiChatService.deleteAdvice(id).then(() => {
      this.adviceCategories.splice(index, 1);
    });
  }

  saveItem(item: any) {

    AiChatService.registerAdvice(
        item.advice.id,
        item.category.id,
        item.advice.assumedQuestion,
        item.advice.response).then((data) => {
      this.fetchAdviceCategories();
    }).catch((e) => {
      alert('更新に失敗しました。')
    }).finally(() => {
      this.dialog = false;
    });
  }

  fetchCategories() {
    AiChatService.categories().then(c => {
      this.categories = c;
    })
  }

  fetchAdviceCategories() {
    AiChatService.adviceCategories().then(c => {
      this.adviceCategories = c;
    })
  }

  downloadAsJsonl(adviceCategories: [any]) {
    const jsonlData = adviceCategories.map(item =>
        JSON.stringify({
          "messages": [
            {
              "role": "system",
              "content": "相手の気持ちを考えて、わかりやすく、傷つかないように適切なアドバイスをしてください。"
            },
            {"role": "user", "content": item.advice.assumedQuestion},
            {"role": "assistant", "content": item.advice.response}
          ]
        })).join('\n');

    const blob = new Blob([jsonlData], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'fine-tuning-data.jsonl';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
