import httpResource from "@/components/infrastructures/functions/http/httpResource";
import Address from "@/components/domains/address/address";

export default class AddressService {

    public static async fetchPrefectures() {
        return (await httpResource.get(`v2/address/prefectures`)).data;
    }

    public static async fetchMunicipalities(prefCode: string) {
        return (await httpResource.get(`v2/address/municipalities?prefectureCode=${prefCode}`)).data;
    }

    public static async fetchDeliveryAddresses() {
        return (await httpResource.get(`v2/address/deliveries`)).data;
    }

    public static async searchFromPostcode(postcode: string): Promise<Address> {
        return (await httpResource.get(`v2/address/search?postcode=${postcode}`)).data as Address;
    }
}