












































































import {Component, Vue} from "vue-property-decorator";
import OrderService from "@/components/domains/order/OrderService";
import ErrorAlert from "@/components/infrastructures/functions/errorAlert";

@Component
export default class OrderNewlist extends Vue {
  name = "OrderNewlist";
  items = [];

  disabledRegister = false;

  // // 手渡し
  // isHandingOver = false;

  beforeMount() {
    OrderService.fetchRequestOrders().then(res => {
      this.items = res;
    })
  }

  register(item: any) {
    this.disabledRegister = true;
    if (item.isHandingOver) {
      this.handingOver(item);
    } else {
      this.send(item);
    }
    this.disabledRegister = false;
  }

  handingOver(item: {
    id: string,
    details: { equipmentId: string, equipmentPhysicalNo: string }[]
  }) {

    const data = {
      deliveryId: item.id,
      physicals: item.details.map(d => {
        return {
          id: d.equipmentId,
          physicalId: d.equipmentPhysicalNo
        }
      })
    };

    OrderService.handingOver(data)
        .then(() => {
          alert("完了しました。");
        })
        .catch((error: any) => {
          ErrorAlert.alert(error);
        })
        .finally(() => {
          this.$router.go(0)
        });
  }

  send(item: {
    id: string,
    slipNumber: string,
    details: { equipmentId: string, equipmentPhysicalNo: string }[]
  }) {

    const data = {
      deliveryId: item.id,
      slipNumber: item.slipNumber,
      physicals: item.details.map(d => {
        return {
          id: d.equipmentId,
          physicalId: d.equipmentPhysicalNo
        }
      })
    };

    OrderService.send(data)
        .then(() => {
          alert("完了しました。");
        })
        .catch((error: any) => {
          ErrorAlert.alert(error);
        })
        .finally(() => {
          this.$router.go(0)
        });
  }
}
