import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AccountService {

    public static async registerEnterpriseAccount(agencyName: string, username: string, password: string, verifyPassword: string) {
        const data = {
            agencyName: agencyName,
            username: username,
            password: password,
            verifyPassword: verifyPassword,
        }
        return (await httpResource.post('/account/register/enterprise', data)).data;
    }

    public static async registerStudioAccount(studioId: string, username: string, password: string, verifyPassword: string) {
        const data = {
            studioId: studioId,
            username: username,
            password: password,
            verifyPassword: verifyPassword,
        }
        return (await httpResource.post('/account/register/studio', data)).data;
    }
}