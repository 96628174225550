













import AddressForm from "@/components/domains/address/AddressForm.vue";
import Address from "@/components/domains/address/address";
import ManufacturingService from "@/components/domains/manufacturing/manufacturingService";
import {Component} from "vue-property-decorator";

@Component({
  components: {AddressForm}
})
export default class ManufacturingLocationRegisterForm extends AddressForm<Address> {
  name = "ManufacturingLocationRegisterForm";

  address = new Address();
  txtName = "";

  register() {
    ManufacturingService.register(this.txtName, this.address)
        .then(id => {
          alert(id);
        })
        .catch((e) => {
          alert(e.response.data.message);
        });
  }

  reset() {
    this.txtName = "";
  }
}
