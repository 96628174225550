
























import {Component, Vue} from "vue-property-decorator";
import EnterpriseAccountRegisterForm from "@/components/domains/account/EnterpriseAccountRegisterForm.vue";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import StudioAccountRegisterForm from "@/components/domains/account/StudioAccountRegisterForm.vue";

@Component({
  components: {StudioAccountRegisterForm, MainPaneTemplate, EnterpriseAccountRegisterForm}
})
export default class Account extends Vue {
  name = "Account"
  tab = 0;
}
