













import {Component, Vue} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import ManufacturingLocationRegisterForm
  from "@/components/domains/manufacturing/ManufacturingLocationRegisterForm.vue";

@Component({
  components: {ManufacturingLocationRegisterForm, MainPaneTemplate}
})
export default class ManufacturingLocation extends Vue {

  name = "ManufacturingLocation";

}
