



























import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import MainPaneTemplate from "@/components/templates/loggedin/MainPaneTemplate.vue";
import UserService from "@/components/domains/analyze/user/userService";
import AiChatLearningForm from "@/components/domains/analyze/ai_chat/AiChatLearningForm.vue";

@Component({
  components: {AiChatLearningForm, MainPaneTemplate}
})
export default class Analyze extends Vue {
  name = "Analyze";

  // https://vuetifyjs.com/ja/api/v-data-footer/#props
  tableOptions: {
    page: number,
    itemsPerPage: number
  } = {
    page: 1,
    itemsPerPage: 5
  };

  userCount = 0;
  usersHeader = [
    // {text: "ID", value: "user.id"},
    {text: "ユーザー名", value: "user.name"},
    {text: "メールアドレス", value: "user.email"},
    {text: "所属団体", value: "user.agency"},
    {text: "Sbプラン", value: "latestPayment.productDisplayName"},
    {text: "Sb開始日", value: "latestPayment.startedAt"},
    {text: "Sb終了申請日", value: "latestPayment.cancelRequestAt"},
    {text: "Sb終了予定日", value: "latestPayment.cancelAt"},
    // {text: "Rt機器", value: ""},
    {text: "Sb契約状態", value: "latestPayment.status", sortable: true}
  ];
  users = [];

  beforeMount() {

    UserService.countUsers().then(count => {
      this.userCount = count;
    })

    this.fetchUsers();
  }

  @Watch('tableOptions')
  fetchUsers() {
    UserService.fetchSubscriptionUsers(this.tableOptions.page - 1, this.tableOptions.itemsPerPage)
        .then(users => {
          this.users = users;
        });
  }
}
