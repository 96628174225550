import {User} from "@/components/domains/auth/user";

export interface AuthState {
    authenticated: boolean;
    currentUser: User;
    intervalName: string;
}

export enum AuthGetterTypes {
    getIsAuthenticated = 'getIsAuthenticated',
    getCurrentUser = 'getCurrentUser',
    getIntervalName = 'getIntervalName',
}

export enum AuthActionTypes {
    logout = 'logout',
    performLogout = 'performLogout',
    login = 'login',
    verifyLogIn = 'verifyLogIn',
    refreshTokenInterval = 'refreshTokenInterval',
    authenticateUser = 'authenticateUser',
    hasRole = 'hasRole',
    hasAuthority = 'hasAuthority',
}

export enum AuthMutationTypes {
    setAuthenticated = 'setAuthenticated',
    setCurrentUser = 'setCurrentUser',
    setIntervalName = 'setIntervalName',
    clearCurrentUser = 'clearCurrentUser',
}
