import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class MailService {

    public static async sendMail(subject: string, text: string, testing: boolean, premium: boolean) {
        const data = {
            subject: subject,
            text: text,
            testing: testing,
            premium: premium
        }
        return (await httpResource.post('/v2/mail/send', data)).data;
    }

    public static async resendMail(historyId: string) {
        return (await httpResource.post(`/v2/mail/resend?historyId=${historyId}`)).data;
    }

    public static async images() {
        return (await httpResource.get('/v2/mail/images')).data;
    }

    public static async uploadImage(file: File) {
        const formData = new FormData();
        formData.append('image', file);
        return (await httpResource.post('/v2/mail/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }));
    }

    public static async deleteImage(id: string) {
        return (await httpResource.post(`/v2/mail/delete-image?id=${id}`)).data;
    }

    public static async mails() {
        return (await httpResource.get(`/v2/mail/mails`)).data;
    }
}