








import {Component, Vue} from "vue-property-decorator";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import LoginForm from "@/components/domains/auth/LoginForm.vue";

@Component({
  components: {LoginForm, DefaultTemplate}
})
export default class Login extends Vue {
  name = "Login";
}
