












import {Component, Model, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NumberField extends Vue {
  @Model('update:modelValue', {type: Number, default: 0})
  readonly value!: number;

  @Prop({type: Number, default: 0})
  readonly min!: number;

  @Prop({type: Number, default: 100})
  readonly max!: number;

  @Prop({type: String, default: ''})
  readonly label!: string;

  get labelText() {
    return this.label || `${this.min}から${this.max}までの値を入力してください`;
  }

  get internalValue() {
    return this.value;
  }

  set internalValue(newValue) {
    this.$emit('update:modelValue', newValue);
  }

  validateAndRoundInput(inputValue: string) {
    let newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue)) {
      newValue = Math.min(Math.max(newValue, this.min), this.max);
      this.internalValue = newValue;
    } else {
      this.internalValue = this.min;
    }
  }
}
