

































import AuthMixin from "@/components/templates/loggedin/authMixin";
import {Component} from "vue-property-decorator";

@Component
export default class LoggedInTemplate extends AuthMixin {
  name = "LoggedInTemplate";
}
