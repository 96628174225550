




























import {Component} from "vue-property-decorator";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import AuthMixin from "@/components/templates/loggedin/authMixin";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";

@Component({
  components: {LoggedInTemplate, DefaultTemplate}
})
export default class Home extends AuthMixin {
  name = 'Home';
  links = [
    {name: '代理店', link: '/agency', roles: ['ROLE_ADMIN']},
    {name: 'アカウント', link: '/account', roles: ['ROLE_ADMIN']},
    {name: 'エンタープライズ', link: '/enterprise', roles: ['ROLE_ADMIN']},
    {name: 'データ分析', link: '/analyze', roles: ['ROLE_ADMIN']},
    {name: 'メール', link: '/mail', roles: ['ROLE_ADMIN']},
    // {name: '製品', link: '/equipment', roles: ['ROLE_ADMIN']},
    {name: '工場', link: '/m-loc', roles: ['ROLE_ADMIN']},
    {name: '注文', link: '/order', roles: ['ROLE_ADMIN', 'ROLE_SUB_ADMIN']},
    {name: '在庫', link: '/stock', roles: ['ROLE_ADMIN', 'ROLE_SUB_ADMIN']},
  ];
}
